"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const components_1 = require("@/components");
const backImg0 = require('@/../styles/imagem/banner-b2b.jpg');
const Home = () => {
    const [teste, setTeste] = (0, react_1.useState)(['']);
    /* console.log (teste) */
    /* useEffect(() => {
        fetch('/texto_home/teste.csv')
            .then((r) => r.text())
            .then((text) => { console.log(text.split('\n')) })
    }, []) */
    return (react_1.default.createElement(components_1.Page, { title: "Home" },
        react_1.default.createElement("div", { className: "home" },
            react_1.default.createElement("div", null,
                react_1.default.createElement("img", { src: backImg0, style: { width: '100%' } }),
                react_1.default.createElement("div", { className: "home container1" },
                    react_1.default.createElement("h1", null, "O B2B \u00E9 um portal onde voc\u00EA cliente BR Motorsport consegue efetuar seus pedidos de forma r\u00E1pida e f\u00E1cil."),
                    react_1.default.createElement("h1", null,
                        "Em caso de d\u00FAvidas entre em contato atrav\u00E9s do email: ",
                        react_1.default.createElement("b", null, "c.silva@brms.com.br")))),
            react_1.default.createElement("div", { className: "home container2" },
                react_1.default.createElement("h1", { className: "pb-3" },
                    "Para melhor aterdermos, a ",
                    react_1.default.createElement("b", null, "BR Motorsport"),
                    " seguir\u00E1 um novo procedimento para solicita\u00E7\u00E3o de garantia. Lembrando que todos os produtos novos comercializados para voc\u00EA lojista est\u00E3o cobertos pela garantia contra defeito de fabrica\u00E7\u00E3o durante o prazo de ",
                    react_1.default.createElement("b", null, "12 (doze) meses (365 dias)"),
                    " contados a partir da data de emiss\u00E3o da Nota fiscal de compra do produto conosco. E para consumidor final continua sendo ",
                    react_1.default.createElement("b", null, "180 dias"),
                    " a partir da data de emiss\u00E3o da Nota fiscal de compra."),
                react_1.default.createElement("h1", null,
                    "Informamos que a patir de agora, todas as solicita\u00E7\u00F5es para o ",
                    react_1.default.createElement("b", null, "SAL"),
                    ", dever\u00E3o ser enviadas mediante o preenchimento do furmul\u00E1rio no link abaixo:"),
                react_1.default.createElement("a", { href: "https://atendimento.brms.com.br/form/5827/", target: "_blank" }, "https://atendimento.brms.com.br/form/5827/"),
                react_1.default.createElement("h1", { style: { color: 'red' } },
                    react_1.default.createElement("b", null, "ATEN\u00C7\u00C3O:"),
                    " N\u00E3o ser\u00E3o atendidos os pedidos enviados para o e-mail: ",
                    react_1.default.createElement("b", null, "sal@brms.com.br"))))));
};
exports.default = Home;

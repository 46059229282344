"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const CardProdutos = (props) => {
    var _a, _b;
    const card = (0, react_1.useRef)(null);
    const produto = props.produto;
    const [testeImg, setTesteImg] = (0, react_1.useState)('');
    const [imagem1, setImagem1] = (0, react_1.useState)(react_1.default.createElement(react_1.default.Fragment, null));
    const [imagem2, setImagem2] = (0, react_1.useState)(react_1.default.createElement(react_1.default.Fragment, null));
    (0, react_1.useEffect)(() => {
        setTesteImg(`https://clienteportal.brms.com.br/images/produto/${produto.codigo}-2.jpg`);
        /*if (testeImg) {
            setImagem1(
                <img className='img1' src={`https://clienteportal.brms.com.br/images/produto/${produto.codigo}-1.jpg?${new Date().getTime()}`} onError={({ currentTarget }) => {
                    setTesteImg(false)
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `https://clienteportal.brms.com.br/images/produto-sem-imagem.jpg?${new Date().getTime()}`;
                }} />
            )
            setImagem2(
                <img className='img2' src={`https://clienteportal.brms.com.br/images/produto/${produto.codigo}-2.jpg?${new Date().getTime()}`} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `https://clienteportal.brms.com.br/images/produto/${produto.codigo}-1.jpg?${new Date().getTime()}`
                }} />
            )
        }*/
    }, [produto]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { key: produto.id, className: 'mini-card', ref: card },
            react_1.default.createElement(react_router_dom_1.Link, { to: `/produto/${produto.id}` },
                react_1.default.createElement("img", { className: 'img1', src: `https://clienteportal.brms.com.br/images/produto/${produto.codigo}-1.jpg`, onError: ({ currentTarget }) => {
                        setTesteImg(`https://clienteportal.brms.com.br/images/produto-sem-imagem.jpg`);
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = `https://clienteportal.brms.com.br/images/produto-sem-imagem.jpg?${new Date().getTime()}`;
                    } }),
                react_1.default.createElement("img", { className: 'img2', src: testeImg, onError: ({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = `https://clienteportal.brms.com.br/images/produto/${produto.codigo}-1.jpg`;
                    } })),
            react_1.default.createElement("div", { className: 'conteudo' },
                react_1.default.createElement(react_router_dom_1.Link, { to: `/produto/${produto.id}` },
                    react_1.default.createElement("p", { className: "titulo" },
                        react_1.default.createElement("strong", null, produto.descricao.slice(0, 45)))),
                react_1.default.createElement("div", { className: 'preco' },
                    react_1.default.createElement("span", null,
                        "Pre\u00E7o: R$",
                        String((_a = produto.preco_lista) === null || _a === void 0 ? void 0 : _a.toFixed(2)).replace('.', ',')),
                    react_1.default.createElement("span", null,
                        "Pre\u00E7o Sugerido: R$",
                        String((_b = produto.preco_sugerido) === null || _b === void 0 ? void 0 : _b.toFixed(2)).replace('.', ',')))))));
};
exports.default = CardProdutos;

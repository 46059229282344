"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Routes = {
    Banco: '/banco',
    Ls2: '/ls2',
    Norisk: '/norisk',
    Home: '/home',
    Dashboard: '/',
    Estoque: '/estoque',
    Financeiro: '/financeiro',
    Upload: '/upload',
    Login: '/login',
    Logout: '/logout',
    ResetPasswordRequest: '/reset-password',
    ResetPasswordChange: '/reset-password-change',
    Files: '/files',
    Teste: '/teste/:id',
    Administradora: '/cadastro/administradora',
    Bandeira: '/cadastro/bandeira',
    Filial: '/cadastro/filial',
    EC: '/cadastro/EC',
    Produto: '/produto/:id',
    Pedidos: '/pedidos',
    BancoImagem: '/banco-de-imagem',
    Market: '/market',
    ProdutosFiltrados: '/market/:id',
    SelCliente: '/selecao-cliente',
    Carrinho: '/carrinho',
    Historico: '/historico',
    Detalhes: '/detalhes/:id',
};
exports.default = Routes;
